@import url("https://fonts.googleapis.com/css2?family=Actor&family=IBM+Plex+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
/* font-family: 'Montserrat', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Rethink Sans', sans-serif;
font-family: 'Quicksand', sans-serif; */

:root {
    --primary-font: "Playfair Display", serif;
    --secondary-font: "Actor", sans-serif;
    --tertiary-font: "Montserrat", sans-serif;
    --fourth-font: "IBM Plex Sans Condensed", sans-serif;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: #990d0e transparent;
    scroll-behavior: smooth;
}

*::-webkit-scrollbar {
    height: 3px !important;
    width: 5px !important;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #990d0e;
    border: transparent;
    border-radius: 25px;
}
html,
body {
    overflow-x: hidden !important;
}
body.modal-open {
    padding: 0 !important;
}

/* global start */
.btn_primary,
.btn_primary_outline {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 16px 30px;
    border-radius: 30px;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    outline: none;
    transition: 0.2s ease;
    border: 1px solid #990d0e;
}

.btn_primary {
    background-color: #990d0e;
    color: #fff;
}

.btn_primary_sm {
    padding: 8px 22px;
}

.btn_primary_outline {
    background-color: #ffffff;
    color: #990d0e;
}

.btn_primary:hover {
    background-color: #880d0d;
}

.btn_primary_outline:hover {
    background-color: #990d0e;
    color: #ffffff;
}

.btn_primary:disabled {
    background: #8a8792;
}

.btn_primary_xs,
.btn_success_xs,
.btn_primary_outline_xs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px 16px;
    border-radius: 30px;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    outline: none;
    transition: 0.2s ease;
    border: 1px solid #990d0e;
}

.btn_primary_xs {
    background-color: #990d0e;
    color: #fff;
}
.btn_success_xs {
    background-color: #2d9d61;
    border-color: #2d9d61;
    color: #fff;
}

.btn_primary_outline_xs {
    background-color: #ffffff;
    color: #990d0e;
}

.btn_primary_xs:hover {
    background-color: #880d0d;
}

.btn_primary_outline_xs:hover {
    background-color: #990d0e;
    color: #ffffff;
}

.primary_pixel_btn_bg {
    position: relative;
}
.primary_pixel_btn_bg::before {
    position: absolute;
    content: "";
    width: 96%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 96%;
    border: 3px solid #000;
    background-color: #880d0d;
    border-radius: 35px;
}
.primary_pixel_btn_bg > .primary_pixel_btn {
    z-index: 1;
    position: relative;
    filter: drop-shadow(0px 0px 0px #880d0d);
}
.primary_pixel_btn:hover {
    cursor: pointer;
    filter: contrast(1.1);
    transform: translateY(1px);
}
.light_pixel_btn:hover {
    cursor: pointer;
    filter: contrast(1.1);
    transform: translateY(1px);
}
.btn_text {
    background-color: transparent;
    color: #990d0e;
    line-height: normal;
    border: none;
    outline: none;
    font-weight: 500;
    transition: all 0.2s ease;
}
.btn_text:hover {
    color: #730b0b;
}
.btn_text_light {
    background-color: transparent;
    color: #fff;
    line-height: normal;
    border: none;
    outline: none;
    font-weight: 500;
    transition: all 0.2s ease;
}
.btn_text_light:hover {
    color: #ccc;
}
.btn_light_outline {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 16px 30px;
    border-radius: 30px;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    outline: none;
    transition: 0.2s ease;
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
}
.btn_light_outline:hover {
    border: 1px solid #990d0e;
    color: #990d0e;
    background: #fff;
}
.gx-30px.row {
    --bs-gutter-x: 30px !important;
}
.scroll_to_top {
    position: fixed;
    width: 60px;
    height: 60px;
    right: 60px;
    bottom: 44px;
    z-index: -1;
    border-radius: 50%;
    background: transparent;
    border: none;
    outline: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
.scroll_to_top.show {
    z-index: 1;
    opacity: 1;
}
/* global end */

/* logo start */
.logo_light,
.logo_dark {
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 5.72px;
    text-transform: uppercase;
}

.logo_light {
    color: #fff;
}

.logo_light > span,
.logo_dark > span {
    color: #990d0e;
}

/* logo end */

/* header/navbar start */
.header_navbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
}

.navbar_top {
    background-color: transparent;
    font-family: var(--secondary-font);
    max-height: 50px;
    transition: all 0.1s ease;
}

.navbar_top p {
    margin: 13px 0;
    font-size: 16px;
    /* font-weight: 600; */
    color: #111112;
}

/* .floating_social_btn span {
  cursor: pointer;
} */

.floating_social_btn .mobile_btn {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
}

.floating_social_btn .mobile_btn a {
    /* font-weight: 600; */
    color: #000;
    text-decoration: none;
}
.floating_social_btn .mobile_btn a:hover {
    opacity: 0.8;
}

.floating_social_btn .mail_btn {
    display: flex;
    align-items: center;
    gap: 10px;
}
.floating_social_btn .mail_btn a {
    /* font-weight: 600; */
    color: #000;
    text-decoration: none;
}
.floating_social_btn .mail_btn a:hover {
    opacity: 0.8;
}

.floating_social_btn .mail_btn::before {
    content: "";
    display: block;
    height: 20px;
    width: 1px;
    background: #8a8792;
    margin-right: 15px;
}

nav.navbar {
    border-top: 1px solid #8a879220;
    border-bottom: 1px solid #8a879220;
    background-color: transparent !important;
    padding: 23px 0;
    transition: all 0.1s ease;
}

nav.navbar.solid {
    background-color: #ffffff !important;
}

.nav_links_lg,
.nav_links_sm {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 25px;
    align-items: center;
}

.nav_links_sm {
    flex-direction: column;
}

.nav_links_lg li,
.nav_links_sm li {
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.nav_links_lg li:hover,
.nav_links_lg li.nav_link_active,
.nav_links_sm li:hover,
.nav_links_sm li.nav_link_active {
    color: #990d0e;
}

.humburger_menu {
    display: none;
}

.header_auth_btn {
    width: 160px;
}

.navbar_auth_profile_btn > button {
    background: none;
    color: #990d0e;
    border-color: #990d0e;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 18px;
    margin-left: 20px;
    font-weight: 500;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
}

.navbar_auth_profile_btn > button::after {
    width: 100%;
    height: 16px;
    padding: 4px 8px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(./assets/svg/arrow_down_primary.svg);
    background-repeat: no-repeat;
}

.navbar_auth_profile_btn.isnotloggedin > button::after {
    display: none;
}

.navbar_auth_profile_btn > button.show,
.navbar_auth_profile_btn > button:active,
.navbar_auth_profile_btn > button:focus,
.navbar_auth_profile_btn > button:hover {
    color: #ffffff;
    background-color: #990d0e !important;
    border-color: #990d0e !important;
}

.navbar_auth_profile_btn > button.show span *,
.navbar_auth_profile_btn > button:active span *,
.navbar_auth_profile_btn > button:focus span *,
.navbar_auth_profile_btn > button:hover span * {
    stroke: #ffffff;
    transition: all 0.2s ease-in-out;
}

.navbar_auth_profile_btn > button.show::after,
.navbar_auth_profile_btn > button:active::after,
.navbar_auth_profile_btn > button:focus::after,
.navbar_auth_profile_btn > button:hover::after {
    filter: brightness(100);
    transition: all 0.2s ease-in-out;
}

.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown {
    padding: 10px 0;
    border: none;
    left: -25% !important;
    right: 0;
    border: 1px solid #e4e3e7;
}

.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item {
    padding: 10px 16px;
    color: #201838;
    font-size: 14px;
    font-weight: 600;
}

.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item svg {
    margin-right: 10px;
}

.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item.active,
.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item:active {
    background-color: #990d0e;
    color: #ffffff;
}

.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item.active svg,
.navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown .dropdown-item:active svg {
    filter: brightness(100);
}
.navbar_auth_profile_img {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    border-radius: 50%;
}

/* header/navbar end */
/* footer start */
.footer {
    background-color: #111112;
    padding: 100px 0 80px;
    position: relative;
}
.footer .curve_bg {
    position: absolute;
    bottom: 100%;
    left: -100px;
    right: -150px;
}
/* .footer .curve_bg {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin-top: 4rem;
} */

.footer_copyright {
    background-color: #1b1b1c;
    color: #ffffff80;
}

.footer_logo_social p {
    margin: 20px 0;
    font-weight: 400;
}

.footer_logo_social {
    color: #ffffff;
}
.footer_logo_social .logo_light img {
    width: 120px;
    cursor: pointer;
}

.footer_social_link p {
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    margin: 30px 0 15px;
}

.footer_social_link span {
    display: flex;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.footer_logo_social ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer_logo_social ul li {
    margin: 11px 0;
}

.footer_logo_social ul li a {
    text-decoration: none;
    color: #fff;
}
.footer_logo_social ul li a:hover {
    opacity: 0.8;
}

.footer_social_link span svg {
    margin: auto;
}

.footer_social_link span:hover {
    opacity: 0.8;
}

footer .footer_links {
    color: #ffffff;
}

footer .footer_links span {
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: 22px;
}

footer .footer_links ul {
    list-style: none;
    padding: 0;
    font-weight: 500;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    margin-top: 20px;
}

footer .footer_links li {
    cursor: pointer;
    border: 1px;
    width: fit-content;
    color: #fafafa;
}

footer .footer_links li + li {
    margin-top: 20px;
}

.footer_newsletter {
    font-family: var(--secondary-font);
    color: #ffffff;
}

.newsletter_heading {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
}

.newsletter_desc {
    font-weight: 400;
    opacity: 0.8;
}

.newsletter_input {
    background: #ffffff;
    max-width: 475px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    padding: 18px 24px;
    margin: 15px 0;
}

.newsletter_input input {
    width: 100%;
    font-weight: 500;
    color: #7e879f;
    border: none;
    outline: none;
}

.newsletter_input + .btn_primary {
    padding: 16px 30px;
}

/* footer end */
/* breadcrumb section start */
ul.breadcrumb {
    display: flex;
    list-style: none;
    padding-left: 0;
    align-items: center;
    margin: 0 0 15px;
    position: relative;
    z-index: 1;
}

.breadcrumb li {
    color: #990d0e;
    font-size: 17px;
    font-weight: 400;
    font-family: var(--secondary-font);
    font-weight: 500;
}

.breadcrumb .breadcrumb_navlink_arrow {
    pointer-events: none;
}

.breadcrumb .breadcrumb_navlink_arrow svg {
    width: 18px;
    height: 18px;
}

.breadcrumb .breadcrumb_navlink_active {
    color: #635d74;
    opacity: 0.8;
}

.breadcrumb li:hover,
.breadcrumb .breadcrumb_navlink_active:hover {
    color: #111112;
    opacity: 1;
    cursor: pointer;
}

/* breadcrumb section end */
/* page-header section start */
.page_header {
    padding: 25px 0 40px;
    background-color: #fff;
}

.page_header h4 {
    font-size: 40px;
    font-family: var(--primary-font);
    font-weight: 700;
}

.page_header p {
    font-size: 18px;
    font-family: var(--secondary-font);
    color: #635d74;
    margin-bottom: 0;
}

.page_header span {
    font-size: 18px;
    font-family: var(--secondary-font);
    font-weight: 500;
    color: #990d0e;
}

/* page-header section end */
/* page-header section start */
.aboutus_page {
    padding: 25px 0 40px;
    background-color: #fff;
}

.aboutus_page .faq_accordian .accordion {
    border: 1px solid #e4e3e7;
}

/* page-header section end */

/* home page start */
/* hero section start */
.hero_section {
    margin-top: 40px;
    position: relative;
    padding-bottom: 172px;
}
.hero_section::after {
    position: absolute;
    content: "";
    background: linear-gradient(180deg, #ffffff 0%, rgba(217, 217, 217, 0.5) 50.52%, #d9d9d9 100%);
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero_section > img {
    width: 100%;
    max-height: 900px;
    object-fit: cover;
    object-position: bottom;
}

.hero_section > div {
    /* position: absolute; */
    top: 30%;
}
.hero_section > div.curve_bg {
    position: absolute;
    top: unset;
    bottom: 0;
    left: -150px;
    right: -150px;
    transform: scaleX(-1);
}
.hero_section > div.curve_bg > img {
    filter: brightness(20);
}

.hero_section .hero_heading {
    font-family: Playfair Display;
    font-size: 56px;
    font-weight: 700;
    line-height: 75px;
    text-align: left;
}

.hero_section .hero_heading span {
    color: #990d0e;
}

.hero_section .hero_desc {
    font-family: var(--secondary-font);
    font-size: 18px;
    line-height: 27px;
    margin: 25px 0;
    color: #000000;
}
.hero_img {
    margin-top: 0;
}
.hero-helper-banner {
    margin-top: -96px;
    gap: 16px;
}
.helper-banner {
    background-color: #f2f2f2;
    padding: 55px 55px;
    border-radius: 10px;
    display: flex;
}
.helper-banner .count-box {
    padding: 0 38px;
}
.helper-banner .count-box span {
    display: block;
    text-align: center;
    font-size: 60px;
    font-weight: 300;
    font-family: var(--fourth-font);
    color: #990d0e;
}
.helper-banner .count-box h6 {
    margin: 30px 0 10px;
    font-size: 24px;
}
.helper-banner .count-box p {
    margin: 0;
    font-size: 14px;
}

/* hero section end */
.section-about-heading {
    color: #990d0e;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--tertiary-font);
}
/* subject section start */
.home_subject_section {
    padding: 120px 0;
    background-image: linear-gradient(#ffffff, #d9d9d959, #d9d9d900);
}

.subject_info span {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #990d0e;
    display: flex;
    margin-bottom: 16px;
}

.subject_info h2 {
    font-family: var(--primary-font);
    font-size: 56px;
    font-weight: 700;
}

.subject_info p {
    font-family: var(--secondary-font);
    margin: 20px 0;
}

.subject_icon_group {
    list-style: none;
    margin: 0;
    padding: 0;
}

.subject_icon_group li {
    margin: 14px 0;
}

.subject_icon_group .subject_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}

.subject_icon_group .subject_icon:hover > span {
    color: #990d0e;
}

.subject_icon_group .subject_icon > div {
    background-color: #fff;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
}
.subject_icon_group .subject_icon > div img {
    width: 100%;
}

.subject_icon_group .subject_icon > span {
    font-family: var(--secondary-font);
    font-size: 18px;
}

/* subject section end */
.home_university_section {
    padding: 84px 0 0;
}
.home_university_logos {
    display: flex;
    justify-content: space-between;
    gap: 32px;
}
.swiper-university-logos .swiper-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
/* tution hub section start */
.home_tution_section {
    padding: 120px 0;
    background-image: linear-gradient(#ffffff, #d9d9d959, #d9d9d900);
}
.flash_card_about > .react-card-flip {
    width: 100%;
    margin: 0 5rem;
}
.flash_card_group .flash_card.flash_card_about .CardFront,
.flash_card_group .flash_card.flash_card_about .CardBack {
    text-align: center;
}

.home_tution_section video {
    max-width: 525px;
    width: 100%;
    margin: auto;
}
.home_tution_section .blend_bg {
    mix-blend-mode: darken;
}

.tution_hub_content {
    padding-left: 32px !important;
}
.tution_hub_content > span {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #990d0e;
    display: flex;
    margin-bottom: 16px;
}

.tution_hub_content h2 {
    font-family: var(--primary-font);
    font-size: 56px;
    font-weight: 700;
}

.tution_hub_content p.sub-heading {
    font-size: 20px;
    color: #635d74;
}

.tution_hub_content > p {
    font-family: var(--secondary-font);
    margin: 20px 0;
}

.tution_hub_content .tution_feature_icon {
    width: 50px;
    height: 50px;
}

.tution_hub_content .tution_feature_heading {
    font-family: var(--secondary-font);
    font-size: 26px;
    font-weight: 500;
    margin: 13px 0;
    display: flex;
}

.tution_hub_content .tution_feature_desc {
    font-family: var(--secondary-font);
    font-size: 20px;
    line-height: normal;
    margin-bottom: 40px;
}

/* tution hub section end */

/* user review section start */
.userreview_card {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0px 7px 29px 0px #64646f33;
}
div.userreview_thumbnail {
    position: relative;
}
img.userreview_thumbnail {
    width: 100%;
    border-radius: 10px 10px 0 0;
    aspect-ratio: 4/3;
    object-fit: cover;
}
.userreview_playicon {
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: -30px;
    right: 20px;
}
.userreview_content {
    margin: 30px 8px 48px;
}
.userreview_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.userreview_info p {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
}
.userreview_count {
    display: flex;
    justify-content: end;
    align-items: center;
}
.userreview_count img {
    width: 20px;
    height: 20px;
}
.userreview_content > p {
    font-size: 14px;
    line-height: 17px;
    color: #635d74;
    margin: 10px 0 0;
}
/* user review section end */

/* blog section start */
.home_blog_section {
    padding: 54px 0;
}

.blog_section_content span {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #990d0e;
    margin-bottom: 16px;
    text-align: center;
    display: block;
}

.blog_section_content h2 {
    font-family: var(--primary-font);
    font-size: 56px;
    font-weight: 700;
    text-align: center;
}

.blog_section_content p.blog_section_desc {
    font-family: var(--secondary-font);
    margin: 20px 0;
    text-align: center;
    max-width: 660px;
    margin: auto;
    color: #635d74;
}

.blog_section_card {
    border: 1px solid #e4e3e7;
    border-radius: 15px;
    padding: 10px;
}

.blog_section_card img {
    border-radius: 10px;
    aspect-ratio: 4/3;
    width: 100%;
    object-fit: cover;
}

.blog_section_card h6 {
    font-family: var(--secondary-font);
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin: 15px 0 10px;
    color: #111112;
}

.blog_section_card > div > p {
    font-family: var(--secondary-font);
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    color: #635d74;
}

.blog_section_card > div {
    padding: 0 8px 12px;
}

.blog_section_card_date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--secondary-font);
    font-size: 14px;
    color: #635d74;
}

.blog_section_card_date p {
    margin: 15px 0 0;
}

.blog_section_card_date p span {
    font-weight: 700;
    color: #111112;
}

/* blog section end */
/* faq section start */
.home_faq_section {
    padding: 116px 0 166px;
    position: relative;
}

.home_faq_section::before {
    position: absolute;
    content: "";
    background: url(./assets/images/home2.png);
    width: 100%;
    height: 100%;
    display: block;
    inset: 0;
    background-size: cover;
    background-position: top;
    z-index: -1;
}

.faq_section_content {
    color: #fff;
}

.faq_section_content span {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    margin-bottom: 16px;
    text-shadow: 1px 1px 3px black;
}

.faq_section_content h2 {
    font-family: var(--primary-font);
    font-size: 56px;
    font-weight: 700;
    /* text-shadow: 1px 1px 3px black; */
    filter: drop-shadow(0px 0px 10px #000);
    backdrop-filter: blur(2px);
}

.faq_section_content p {
    font-family: var(--secondary-font);
    margin: 20px 0;
    padding-right: 3rem;
    font-size: 18px;
    /* text-shadow: 1px 1px 3px black; */
    filter: drop-shadow(0px 0px 10px #000);
    backdrop-filter: blur(2px);
    color: #fff;
}

.faq_accordian .accordion {
    border-radius: 15px;
    overflow: hidden;
    padding: 0 28px;
    background-color: #fff;
}

.faq_accordian .accordion-item {
    border-left: 0;
    border-right: 0;
}

.faq_accordian .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
}
.faq_accordian .accordion-item:last-of-type {
    border-bottom: 0;
}
.faq_accordian .accordion {
    padding: 0;
}
.faq_accordian .accordion-button {
    padding: 20px 5rem 20px 0;
    font-size: 28px;
    font-weight: 400;
    font-family: var(--secondary-font);
    color: #111112;
}

.faq_accordian .accordion-button:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.faq_accordian .accordion-button::after {
    position: absolute;
    right: 0;
    width: 41px;
    height: 40px;
    background-size: auto;
    background-image: url(./assets/svg/accordian_close.svg);
}

.faq_accordian .accordion-button:not(.collapsed)::after {
    background-image: url(./assets/svg/accordian_open.svg);
    transform: none;
}

.faq_accordian .accordion-body {
    padding: 0 70px 2rem;
    font-family: var(--secondary-font);
    font-size: 18px;
    color: #636d84;
}

.faq_accordian .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
    box-shadow: none;
}

/* faq section end */

/* get started start */
.home_get_started_section {
    padding: 50px 0;
}
.get_started_info {
    padding-right: 32px;
    padding-bottom: 32px;
}
.get_started_info h2 {
    font-size: 60px;
    font-weight: 400;
    line-height: 72px;
    padding-right: 112px;
}
.get_started_info p {
    margin: 30px 0 50px;
    font-size: 22px;
    line-height: 30px;
}
.get_started_features {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    box-shadow: 0px 7px 29px 0px #64646f33;
}
.get_started_features > div {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    cursor: pointer;
}
.get_started_features > div > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    transition: all 0.2s ease;
}
.get_started_features > div:nth-child(1),
.get_started_features > div:nth-child(2),
.get_started_features > div:nth-child(3) {
    border-bottom: 1px solid #990d0e44;
}
.get_started_features > div:nth-child(2),
.get_started_features > div:nth-child(5) {
    width: 33.34%;
    border-left: 1px solid #990d0e44;
    border-right: 1px solid #990d0e44;
}
.get_started_features > div > div:hover {
    transform: scale(1.05);
    background-color: #990d0e;
}
.get_started_features div img {
    width: 80px;
    height: 80px;
}
.get_started_features > div > div:hover img {
    filter: brightness(20);
}
.get_started_features div span {
    color: #111112;
    font-size: 20px;
    font-family: var(--primary-font);
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
    line-height: 20px;
}
.get_started_features > div > div:hover span {
    color: #fff;
}
/* get started end */

/* user review start */
.home_userreview_section {
    padding: 90px 0 54px;
}
.userreview_heading {
    font-size: 56px;
    line-height: 74px;
    text-align: center;
    font-weight: 700;
    font-family: var(--primary-font);
    max-width: 750px;
    margin: 0 auto;
    word-wrap: break-word;
}
.userreview_desc {
    color: #635d74;
    line-height: 19px;
    text-align: center;
    max-width: 680px;
    margin: 20px auto;
}
/* user review end */

/* still have question section start */

.home_havequestion_section {
    position: relative;
}
.home_havequestion_section img {
    width: 100%;
    height: 840px;
    object-fit: cover;
    object-position: center;
}

/* .home_havequestion_section::before {
  content: "";
  background: url(./assets/images/university-image.png);
  width: 100%;
  height: 100%;
  display: block;
  inset: 0;
  background-size: cover;
  background-position: top;
} */
.havequestion_content {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    top: 150px;
}
.havequestion_content > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.havequestion_content h2 {
    font-size: 60px;
    line-height: 60px;
    font-family: var(--primary-font);
    margin-bottom: 60px;
    font-weight: 400;
}
.havequestion_content h2 span {
    color: #990d0e;
}
/* still have question section end */

/* home page end */

/* login/signup start */

.login_signup_page {
    position: relative;
    min-height: 100vh;
    padding: 80px 0;
    overflow: hidden;
}
.login_signup_page .logo_light > img {
    filter: drop-shadow(0px 0px 5px #bbb) drop-shadow(0px 0px 10px #aaa);
    backdrop-filter: blur(2px);
    cursor: pointer;
}

.login_signup_page::before {
    content: "";
    position: absolute;
    background-image: url(./assets/images/home2.png);
    background-repeat: no-repeat;
    width: 50%;
    background-size: cover;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}
/* .login_signup_page > img {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
} */

.login_signup_page .login_signup_content_left,
.login_signup_page .login_signup_content_right {
    margin: auto;
}

.login_signup_content_left h1 {
    color: #fff;
    text-align: center;
    font-family: var(--primary-font);
    font-size: 56px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    filter: drop-shadow(0px 0px 10px #000);
    backdrop-filter: blur(2px);
}

.login_signup_content_left p {
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-family: var(--secondary-font);
    line-height: normal;
    padding: 0 3rem;
    filter: drop-shadow(0px 0px 10px #000);
    backdrop-filter: blur(2px);
}

.login_signup_content_right h3 {
    color: #111112;
    font-family: var(--primary-font);
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    line-height: 123%;
    /* 49.2px */
}

.login_signup_content_right > p {
    color: #635d74;
    text-align: center;
    font-family: var(--secondary-font);
    line-height: 139.9%;
    /* 22.384px */
    padding: 0 3rem;
    margin: 20px 0 40px;
}

.mobile_number_country_code {
    border: 1px solid #e4e3e7;
    border-radius: 12px;
}

.mobile_number_country_code > .dropdown > button {
    width: 100%;
    border-radius: 0;
    padding: 14px 20px;
    background-color: #8a879230;
    border: none;
    outline: none;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    color: #111112;
    font-family: var(--secondary-font);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.mobile_number_country_code > .dropdown > button.btn-check:checked + .btn,
.mobile_number_country_code > .dropdown > button.btn.active,
.mobile_number_country_code > .dropdown > button.btn.show,
.mobile_number_country_code > .dropdown > button.btn:first-child:active,
.mobile_number_country_code > .dropdown > button:not(.btn-check) + .btn:active {
    border-color: none;
    background-color: #8a879230;
}

.mobile_number_country_code > input {
    border: none;
    outline: none;
    padding: 18px 20px;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.mobile_number_country_code > input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.auth_option_divider {
    margin: 3rem auto 0 !important;
    width: 100%;
    height: 1px;
    background: #e4e3e7;
    position: relative;
    margin: 0 auto;
}

.auth_option_divider::after {
    content: "OR";
    position: absolute;
    font-size: 20px;
    color: #635d74;
    background: #fff;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 35px;
    text-align: center;
    transform: translate(-50%, -50%);
    font-weight: 500;
}
.form-group label {
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    color: #999;
}
.form-group .form-control {
    padding: 18px 20px;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #e4e3e7;
    border-radius: 12px;
}
.form-group .form-control::placeholder {
    color: #aaa;
    font-weight: 400;
}
.form-group .form-control:focus {
    box-shadow: none !important;
    border-color: #e4e3e7;
}
select.form-control:invalid {
    color: #aaa; /* Placeholder color */
}

/* Ensure that the real options have a different color */
select.form-control option {
    color: #000;
}
.register_btn {
    color: #888;
}
.register_btn span {
    color: #990d0e;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
}
.register_btn span:hover {
    color: #730b0b;
}

.no-select {
    user-select: none; /* For modern browsers */
    -webkit-user-select: none; /* For Safari */
    -moz-user-select: none; /* For Firefox */
    -ms-user-select: none; /* For Internet Explorer/Edge */
}

.modal-body {
    pointer-events: none; /* Disable any interactions */
}

.subscription-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: #730b0b;
}

.subscription-message h1 {
    text-align: center;
}

.subscribe_plan_select {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 100px;
    justify-content: center;
}

.subscribe_plan_select li > div {
    position: relative;
    height: 100%;
}

.subscribe_plan_select li > div > input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.subscribe_plan_select li > div > input:checked + div {
    border-color: #990d0e;
}

.subscribe_plan_select li > div > div {
    border-radius: 12px;
    border: 2px solid #e4e3e7;
    padding: 32px 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    height: 100%;
}
.subscribe_plan_select li > div > div > ul {
    list-style: none;
    padding: 0;
}
.subscribe_plan_select li > div > div > ul li {
    position: relative;
    font-size: 13px;
    font-weight: 500;
    color: #777;
    display: flex;
    align-items: center;
}
.subscribe_plan_select li > div > div > ul li::before {
    content: "";
    background: url(./assets/svg/red_tick.svg) no-repeat;
    display: flex;
    background-size: 100%;
    min-width: 34px;
    height: 34px;
}

.subscribe_plan_select li > div:hover > div {
    border-color: #990d0e;
}

.subscribe_plan_select li h5 {
    color: #111112;
    font-family: var(--secondary-font);
    line-height: normal;
    text-align: center;
}

.subscribe_plan_select li span {
    color: #111112;
    font-family: var(--secondary-font);
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
}

.subscribe_plan_select li span span {
    color: #635d74;
    font-family: var(--secondary-font);
    font-size: 16px;
    line-height: normal;
}

.subscribe_plan_select .selected_plan_radio_btn {
    background-color: #f1f1f1;
    border-radius: 16px;
    padding: 6px 20px;
    font-weight: 500;
    text-align: center;
}

.subscribe_plan_select .selected_plan_radio_btn span {
    display: none;
}

.subscribe_plan_select li > div > input:checked + div .selected_plan_radio_btn {
    background-color: #990d0e;
    color: #fff;
}

.subscribe_plan_select .selected_plan_radio_btn span svg {
    width: 100%;
    height: 100%;
}

.Toastify__toast-container.Toastify__toast-container--top-right:has(div.custom_width) {
    width: 430px !important;
}

/* login/signup end */
/* account page start */
/* my-profile start */
.account_page {
    padding: 60px 0 150px;
    background-color: #e4e3e740;
}

.account_image_tabs {
    border-radius: 15px;
    overflow: hidden;
    font-family: var(--secondary-font);
}

.profile_name_card {
    background-color: #990d0e;
    padding: 40px 22px;
    color: #ffffff;
    flex-direction: column;
    align-items: center;
}

.profile_name_card > div {
    width: 104px;
    height: 104px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    margin-bottom: 20px;
}

.profile_name_card > div > img {
    max-width: 100px;
    max-height: 100px;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 50%;
}

.profile_name_card p {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.profile_name_card span {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    letter-spacing: 0.3px;
    /* color: #ffffff; */
}

.account_page .nav-pills,
.account_page .accordion {
    background-color: #fff;
    --bs-accordion-border-width: 0;
}

.account_page .nav-pills.my_profile,
.account_page .accordion.my_profile {
    margin: 0;
    padding: 10px 0 25px;
}

.account_page .nav-pills .nav-item + .nav-item,
.account_page .accordion .accordion-item + .accordion-item {
    /* border-top: 1px solid #E4E3E7; */
}

.account_page .nav-pills .nav-link,
.account_page .accordion .accordion-button {
    padding: 18px 20px;
    display: flex;
    align-items: center;
    border-radius: 0;
}

.account_page .my_profile.nav-pills .nav-link.active,
.account_page .my_profile.nav-pills .show > .nav-link,
.account_page .my_profile.accordion .accordion-button:not(.collapsed) {
    background-color: #fff;
}

.account_page .my_profile.accordion .accordion-button:focus {
    box-shadow: none;
}

.account_page .my_profile.nav-pills .nav-link.active span,
.account_page .my_profile.nav-pills .show > .nav-link span,
.account_page .my_profile.accordion .accordion-button:not(.collapsed) span {
    color: #990d0e;
}

.account_page .my_consultation.nav-pills .nav-link.active,
.account_page .my_consultation.nav-pills .show > .nav-link,
.account_page .my_consultation.accordion .accordion-button:not(.collapsed) {
    background-color: #6950ba;
}

.account_page .my_consultation.nav-pills .nav-link.active *,
.account_page .my_consultation.nav-pills .show > .nav-link *,
.account_page .my_consultation.accordion .accordion-button:not(.collapsed) * {
    color: #ffffff;
}

.account_page .my_consultation.nav-pills .nav-link.active svg,
.account_page .my_consultation.nav-pills .show > .nav-link svg,
.account_page .my_consultation.accordion .accordion-button:not(.collapsed)::after {
    filter: brightness(50);
}

.account_page .nav-pills .nav-link span,
.account_page .accordion .accordion-button span {
    margin: 0 auto 0 15px;
    color: #000;
    letter-spacing: 0.1px;
}

.account_page .my_consultation.nav-pills .nav-link span,
.account_page .my_consultation.accordion .accordion-button span {
    margin: 0 auto 0 0;
}

.account_page .tab-content {
    height: 100%;
}

.account_page .tab-content .tab-pane {
    height: 100%;
}

.account_page .tab_content_box {
    border-radius: 15px;
    overflow: hidden;
    height: 100%;
    background-color: #fff;
}

.account_page .tab_content_box .tab_content_heading {
    background-color: #fff;
    padding: 32px 42px 18px;
}

.account_page .tab_content_box h6 {
    color: #111112;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    font-family: var(--primary-font);
}

/* 
.account_page .tab_content_heading button {
  color: #990D0E;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  border: none; 
  margin-left: auto;
  padding: 10px 18px;
}
*/

/* .account_page .tab_content_box button:hover {
  color: #770b0b;
} */

.account_page .tab_content_box .tab_content {
    padding: 8px 3rem 25px;
}

.account_page .tab_content + .tab_content {
    border-top: 1px solid #e4e3e7;
}

.account_page .tab_content_box .form-label {
    font-size: 14px;
    font-weight: 500;
}

.account_page .tab_content_box .form-label::after {
    content: "*";
    color: #e03616;
}

.account_page .input_verify_otp .form-label::after {
    content: none;
}

.account_page .input_verify_otp .form-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account_page .input_verify_otp .form-label button {
    font-size: 14px;
    font-weight: 600;
    color: #6950ba;
    background: none;
    outline: none;
    border: none;
}

.account_page .input_verify_otp .form-label button:hover {
    color: #403469;
}

.account_page .input_verify_otp .form-label span {
    font-size: 12px;
    color: #201838;
}

.account_page .tab_content_box .input_verify_otp {
    position: relative;
}

.account_page .tab_content_box .form-control {
    line-height: normal;
    font-weight: 600;
    color: #201838;
    border-radius: 5px;
    border: 1px solid #e4e3e7;
    background: #e4e3e7;
    padding: 17px 16px;
}
.account_page .tab_content_box .form_gender_select {
    line-height: normal;
    font-weight: 600;
    color: #201838;
    border-radius: 5px;
    border: 1px solid #e4e3e7;
    background: #e4e3e7;
    padding: 17px 16px;
    background-image: linear-gradient(45deg, transparent 50%, black 60%), linear-gradient(135deg, black 40%, transparent 50%) !important;
    background-position: calc(100% - 28px) 23px, calc(100% - 20px) 23px, 100% 0;
    background-size: 8px 8px, 8px 8px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.account_page .tab_content_box .form-control::placeholder {
    color: #8a8792;
    font-weight: 400;
}

.account_page .tab_content_box .form_gender_select:hover,
.account_page .tab_content_box .form_gender_select:focus,
.account_page .tab_content_box .form-control:hover,
.account_page .tab_content_box .form-control:focus {
    border-color: #cbcacc;
    box-shadow: none;
}

.account_page .tab_content_box .input_verify_otp .input_verified,
.account_page .tab_content_box .input_verify_otp .input_getotp {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    font-size: 14px;
}

.account_page .tab_content_box .input_verify_otp .input_verified {
    color: #2d9d61;
    pointer-events: none;
    font-weight: 500;
}

.account_page .tab_content_box .input_verify_otp .input_getotp {
    color: #6950ba;
    font-weight: 600;
    cursor: pointer;
}

.account_page .tab_content_box .input_verify_otp .input_getotp:hover {
    color: #403469;
}

.account_page .btn_primary {
    padding: 15px 2rem;
}

/* my-profile end */
/* subject page start */
/* subject card component start */
.subject_page {
    background-color: #e4e3e740;
    padding: 40px 0 200px;
}

.subject_card_component {
    background-color: #fff;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
}

.subject_card_component > div {
    background-color: #e4e3e7;
    border-radius: 12px;
    aspect-ratio: 4/3;
    position: relative;
    overflow: hidden;
}

.subject_card_component > div img {
    width: 100%;
    object-fit: contain;
    mix-blend-mode: multiply;
}

.progress_strip {
    background-color: #fff;
    padding: 10px 16px;
    border-radius: 8px;
    margin: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.progress_strip p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--secondary-font);
    margin-bottom: 8px;
}

.progress_strip .progress,
.progress_strip .progress-stacked {
    height: 9px;
    background-color: #d9d9d9;
}

.subject_card_component > p {
    font-family: var(--secondary-font);
    font-weight: 600;
    font-size: 20px;
    margin: 16px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subject_card_component > p > span {
    background-color: #990d0e;
    color: #fff;
    border-radius: 4px;
    padding: 1px 6px;
    font-size: 12px;
    letter-spacing: 0.6px;
}

/* subject card component end */
/* subject page end */
/* blogs page start */
.blogs_page {
    background-color: #e4e3e740;
    padding: 40px 0 200px;
}

/* blog card component start */
.blog_card_component {
    background-color: #fff;
    padding: 18px;
    border-radius: 12px;
    font-family: var(--secondary-font);
}

.blog_card_component_img img {
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    border-radius: 10px;
}

.blog_card_component_content {
    display: flex;
    flex-direction: column;
}

.blog_card_component_content h5 {
    color: #121416;
    font-size: 28px;
    font-weight: 700;
    margin-top: 8px;
}

.blog_card_component_content span {
    color: #635d74;
    font-weight: 500;
}

.blog_card_component_content > p {
    color: #635d74;
    font-weight: 500;
}

.blog_uploadby {
    display: flex;
    align-items: center;
    margin: auto 0 1rem;
}

.blog_uploadby div {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.blog_uploadby img {
    width: 100%;
    object-fit: cover;
}

.blog_uploadby p {
    color: #111112;
    font-weight: 500;
    margin: 0;
}

/* blog card component end */
/* blogs page end */
/* blog detail page start */
.blog_detail_page {
    font-family: var(--secondary-font);
    margin-bottom: 5rem;
}

.blog_detail_page > img {
    width: 100%;
    aspect-ratio: 16/6;
    border-radius: 15px;
    object-fit: cover;
}

.blog_detail_page h3 {
    font-weight: 700;
    font-size: 45px;
    margin-top: 38px;
}

.blog_detail_page > div {
    display: flex;
    align-items: center;
    gap: 4rem;
    margin: 15px 0 20px;
}

.blog_detail_page .blog_uploadby {
    margin: 0;
}

.blog_detail_page > hr {
    opacity: 0.1;
}

/* blog detail page end */
/* aboutus page start */
.aboutus_page {
    background-color: #e4e3e740;
    padding: 100px 0 200px;
}

.aboutus_page_content > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.aboutus_page_content h2 {
    font-family: var(--primary-font);
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    color: #212529;
}
.aboutus_page_content h4 {
    font-family: var(--primary-font);
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
}

.aboutus_page_content h4 span {
    color: #990d0e;
}

.aboutus_page_content p {
    font-family: var(--secondary-font);
    line-height: 24px;
    color: #635d74;
    margin-top: 25px;
}
.aboutus_page_content button {
    width: 75%;
}

/* aboutus page end */
/* tution hub page start */
.tutionhub_hero_section {
    padding: 50px 0;
}

.tutionhub_hero_section h2 {
    font-family: var(--primary-font);
    font-size: 56px;
    font-weight: 700;
    margin: 25px 0;
}

.tutionhub_hero_section h2 span {
    color: #990d0e;
}

.tutionhub_hero_section p {
    font-family: var(--secondary-font);
    font-size: 18px;
}

.tutionhub_whychoose,
.tutionhub_howitworks,
.tutionhub_viewour {
    padding: 60px 0;
    background-image: linear-gradient(#ffffff, #d9d9d959, #d9d9d900);
}

.tutionhub_whychoose .section_name,
.tutionhub_viewour .section_name {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #990d0e;
}

.tutionhub_whychoose .section_heading,
.tutionhub_viewour .section_heading {
    font-family: var(--primary-font);
    font-size: 56px;
    font-weight: 700;
    line-height: 75px;
}

.tutionhub_whychoose_card {
    font-family: var(--secondary-font);
    padding: 24px 28px;
    border: 1px solid #e4e3e7;
    background-color: #fff;
    border-radius: 16px;
    height: 100%;
    display: flex;
    gap: 24px;
}

.tutionhub_whychoose_card span {
    max-width: 100px;
    min-width: 100px;
    width: 100%;
    height: 100px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.tutionhub_whychoose_card h6 {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
}

.tutionhub_whychoose_card p {
    font-weight: 500;
    line-height: 20px;
    color: #635d74;
}

.tutionhub_cost {
    background-color: #990d0e;
    color: #fff;
    padding: 48px 0;
}

.tutionhub_cost h2 {
    font-family: var(--primary-font);
    font-size: 50px;
    font-weight: 700;
    line-height: 67px;
    margin-bottom: 15px;
}

.tutionhub_cost p {
    font-family: var(--secondary-font);
    font-size: 20px;
    line-height: 25px;
}

.tutionhub_howitworks h2 {
    font-family: var(--primary-font);
    font-size: 50px;
    font-weight: 700;
    line-height: 67px;
}

.tutionhub_howitworks span.subheading {
    font-family: var(--secondary-font);
    font-weight: 500;
    line-height: 20px;
    margin-top: 20px;
}

.tutionhub_howitworks ul {
    font-family: var(--secondary-font);
    font-weight: 500;
    line-height: 20px;
    color: #990d0e;
    margin: 20px 0 30px;
}

.tutionhub_howitword_card {
    font-family: var(--secondary-font);
    padding: 24px 28px;
    border: 1px solid #e4e3e7;
    background-color: #fff;
    border-radius: 16px;
    height: 100%;
}

.tutionhub_howitword_card span {
    max-width: 100px;
    min-width: 100px;
    width: 100%;
    height: 100px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.tutionhub_howitword_card h6 {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 15px 0;
}

.tutionhub_howitword_card p {
    font-weight: 500;
    line-height: 20px;
    color: #635d74;
    margin: 0;
}

.tutionhub_howitworks p.description {
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #111112;
    padding-right: 20rem;
}

.tutionhub_contact {
    padding: 88px 0;
    background: #f5f5f5;
}

.tutionhub_contact .section_name {
    font-family: var(--secondary-font);
    font-size: 18px;
    line-height: 22px;
    color: #990d0e;
}

.tutionhub_contact .section_heading {
    font-family: var(--primary-font);
    font-size: 56px;
    font-weight: 700;
    line-height: 75px;
    margin: 16px 0;
}

.tutionhub_contact p {
    font-family: var(--secondary-font);
    line-height: 20px;
    color: #635d74;
}

.tutionhub_contact .form-label {
    font-family: var(--secondary-font);
    font-size: 14px;
    line-height: 18px;
    color: #635d74;
}

.tutionhub_contact .form-control {
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #635d74;
    border: 1px solid transparent;
    padding: 20px 22px;
    border-radius: 15px;
}

.tutionhub_contact .form-control::placeholder {
    color: #635d7490;
    font-weight: 500;
}

.tutionhub_contact .form-control:focus {
    border-color: #635d7450;
    outline: 0;
    box-shadow: none;
}

.viewour_course_card {
    background-color: #fff;
    border: 1px solid #e4e3e7;
    padding: 12px;
    border-radius: 17px;
}

.viewour_course_card > img {
    aspect-ratio: 16/10;
    width: 100%;
    border-radius: 10px;
}

.viewour_course_card h6 {
    font-family: var(--secondary-font);
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    color: #111112;
    margin: 0;
}

.viewour_course_card > p {
    font-family: var(--secondary-font);
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #990d0e;
    margin: 15px 0 5px;
}

.viewour_course_card .course_card_extra_info {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 0;
    border-bottom: 1px solid #e4e3e7;
}

.viewour_course_card .course_card_extra_info span {
    max-width: 58px;
    min-width: 58px;
    width: 100%;
    height: 58px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 6px;
}

.viewour_course_card div p {
    font-family: var(--secondary-font);
    line-height: 20px;
    margin: 8px 0 0;
}

.viewour_course_card .course_card_btn_price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

/* tution hub page end */
/* topic detail page start */
.topic_detail {
    padding-bottom: 105px;
}

.topicdetail_content.row {
    margin-top: -64px;
}

.topic_detail .nav-pills,
.topic_detail .accordion {
    background-color: #f7f7f8;
    border: none;
    border-radius: 15px;
    padding: 14px 10px;
    overflow: hidden;
    --bs-accordion-border-width: 0;
    margin-top: 64px;
}

.topic_detail .nav-pills > h6,
.topic_detail .accordion > h6 {
    font-family: var(--primary-font);
    font-size: 26px;
    font-weight: 700;
    line-height: 35px;
    color: #111112;
    margin: 14px 5px;
}

.topic_detail .nav-pills .nav-item + .nav-item,
.topic_detail .accordion .accordion-item + .accordion-item {
    margin-top: 10px;
}

.topic_detail .nav-pills .nav-link,
.topic_detail .accordion .accordion-button {
    padding: 12px 10px 12px 12px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
}

.topic_detail .accordion .accordion-button:focus {
    box-shadow: none;
}
.topic_title .topic_wrapper{
    max-height: 70vh;
    overflow-x: auto;
}
.topic_detail .topic_title.nav-pills .nav-link.active,
.topic_detail .topic_title.nav-pills .show > .nav-link,
.topic_detail .topic_title.accordion .accordion-button:not(.collapsed) {
    background-color: #fff;
}

.topic_detail .topic_title.nav-pills .nav-link.active *,
.topic_detail .topic_title.nav-pills .show > .nav-link *,
.topic_detail .topic_title.accordion .accordion-button:not(.collapsed) * {
    color: #990d0e;
    font-weight: 600;
    letter-spacing: 0.25px;
}

.topic_detail .nav-pills .nav-link.decent_rated_topic,
.topic_detail .accordion .accordion-button.decent_rated_topic,
.topic_detail .topic_title.nav-pills .nav-link.decent_rated_topic.active,
.topic_detail .topic_title.nav-pills .show > .nav-link.decent_rated_topic,
.topic_detail .topic_title.accordion .decent_rated_topic .accordion-button.collapsed,
.topic_detail .topic_title.accordion .decent_rated_topic .accordion-button:not(.collapsed) {
    background-color: #e0f1e8;
}

.topic_detail .topic_title.nav-pills .nav-link.decent_rated_topic.active *,
.topic_detail .topic_title.nav-pills .show > .nav-link.decent_rated_topic *,
.topic_detail .topic_title.accordion .decent_rated_topic .accordion-button:not(.collapsed) * {
    color: #2d9d61;
    font-weight: 600;
}

.topic_detail .nav-pills .nav-link.bad_rated_topic,
.topic_detail .accordion .accordion-button.bad_rated_topic,
.topic_detail .topic_title.nav-pills .nav-link.bad_rated_topic.active,
.topic_detail .topic_title.nav-pills .show > .nav-link.bad_rated_topic,
.topic_detail .topic_title.accordion .bad_rated_topic .accordion-button.collapsed,
.topic_detail .topic_title.accordion .bad_rated_topic .accordion-button:not(.collapsed) {
    background-color: #ffe9dd;
}

.topic_detail .topic_title.nav-pills .nav-link.bad_rated_topic.active *,
.topic_detail .topic_title.nav-pills .show > .nav-link.bad_rated_topic *,
.topic_detail .topic_title.accordion .bad_rated_topic .accordion-button:not(.collapsed) * {
    color: #ff5c00;
    font-weight: 600;
}

.topic_detail .nav-pills .nav-link.average_rated_topic,
.topic_detail .accordion .accordion-button.average_rated_topic,
.topic_detail .topic_title.nav-pills .nav-link.average_rated_topic.active,
.topic_detail .topic_title.nav-pills .show > .nav-link.average_rated_topic,
.topic_detail .topic_title.accordion .average_rated_topic .accordion-button.collapsed,
.topic_detail .topic_title.accordion .average_rated_topic .accordion-button:not(.collapsed) {
    background-color: #fff4cd;
}

.topic_detail .topic_title.nav-pills .nav-link.average_rated_topic.active *,
.topic_detail .topic_title.nav-pills .show > .nav-link.average_rated_topic *,
.topic_detail .topic_title.accordion .average_rated_topic .accordion-button:not(.collapsed) * {
    color: #ffb800;
    font-weight: 600;
}

/* .topic_detail .topic_title.nav-pills .nav-link.active svg,
.topic_detail .topic_title.nav-pills .show>.nav-link svg,
.topic_detail .topic_title.accordion .accordion-button:not(.collapsed)::after {
  filter: brightness(50);
} */

.topic_detail .nav-pills .nav-link span,
.topic_detail .accordion .accordion-button span {
    margin: 0 auto 0 0;
    color: #635d74;
    font-family: var(--secondary-font);
}

.topic_detail .nav-pills .nav-link.decent_rated_topic span,
.topic_detail .accordion .accordion-button.decent_rated_topic span {
    color: #2d9d61;
}

.topic_detail .nav-pills .nav-link.bad_rated_topic span,
.topic_detail .accordion .accordion-button.bad_rated_topic span {
    color: #ff5c00;
}

.topic_detail .nav-pills .nav-link.average_rated_topic span,
.topic_detail .accordion .accordion-button.average_rated_topic span {
    color: #ffb800;
}

.topic_detail .topic_title.nav-pills .nav-link svg,
.topic_detail .topic_title.accordion .accordion-button svg {
    min-width: 24px;
    max-width: 24px;
}

.topic_review_select {
    list-style: none;
    padding: 0;
    margin-top: 24px !important;
    margin-bottom: 24px;
}

.topic_review_select li > div {
    position: relative;
}

.topic_review_select li > div > input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.topic_review_select li > div > input:checked + div {
    background-color: #2d9d6126;
}

.topic_review_select li > div > div {
    border-radius: 12px;
    border: 1px solid #e4e3e7;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.topic_review_select li > div:hover > div {
    border-color: #2d9d6170;
}

.topic_review_select li p {
    color: #111112;
    font-family: var(--secondary-font);
    font-weight: 500;
    margin: 0;
}

.topic_review_select .selected_plan_radio_btn {
    width: 25px;
    height: 25px;
    border: 1px solid #e4e3e7;
    border-radius: 6px;
}

.topic_review_select .selected_plan_radio_btn span {
    display: none;
}

.topic_review_select li > div > input:checked + div .selected_plan_radio_btn span {
    display: flex;
}

.topic_review_select .selected_plan_radio_btn span svg {
    width: 100%;
    height: 100%;
}
.notes_tab_column {
    position: relative;
}
.notes_tab_column > .topic-action-btn {
    position: absolute;
    right: 12px;
    top: 78px;
    gap: 32px;
}
.notes_tab_column > .topic-action-btn i {
    font-size: 26px;
    color: #730b0b;
}
.topic_answer_tabs {
    background: #f7f7f8;
    border: 1px solid #e4e3e7;
    border-radius: 12px;
    overflow: hidden;
}

.topic_answer_tabs li {
    background: #f7f7f8;
    outline: none !important;
}

.topic_answer_tabs li button {
    color: #111112;
    font-family: var(--secondary-font);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    padding: 20px;
    border: none !important;
}

.topic_answer_tabs .nav-item.show .nav-link,
.topic_answer_tabs .nav-link.active {
    color: #990d0e !important;
    /* border: none !important; */
    border-bottom: 1px solid #e4e3e7 !important;
}

.topic_answer_tabs .nav-item.show .nav-link:focus,
.topic_answer_tabs .nav-link:hover {
    color: #777 !important;
}

.topic_answer_tabs li + li {
    border-left: 1px solid #e4e3e7;
}

.topic_answer_tabs + .tab-content h3 {
    color: #111112;
    font-family: var(--secondary-font);
    font-size: 40px;
    font-weight: 700;
    margin: 64px 0 0;
}

.topic_answer_tabs + .tab-content p {
    color: #635d74;
    font-family: var(--secondary-font);
    margin: 64px 0 0;
}

.premium-card {
    width: 100%;
    max-width: 600px;
    background: #990d0e;
    color: #fff;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    margin: 8rem 0 2rem;
    .blur-container {
        position: relative;
        min-height: 400px;
        .overlay {
            display: block;
            position: absolute;
            top: 8%;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            z-index: 10;
            .overlay-lock {
                width: 64px !important;
                height: 108px;
                background: url(./assets/images/lock.svg) no-repeat bottom center;
                background-size: contain;
                margin: 0 auto;
            }
            .lock-message {
                font-size: 36px;
                font-weight: 400;
                line-height: 43.2px;
                width: 500px;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
}

.flashcard {
    background-color: #f7f7f8;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 2px 10px #ddd;
}
.flashcard > h5 {
    background-color: #e8e8e8;
    padding: 1rem;
    box-shadow: 0px 2px 5px #e8e8e8;
    text-align: center;
    font-weight: 700;
    margin: 0;
}
.flashcard > div {
    padding: 1rem;
}
.flashcard h4 {
    text-align: center;
    font-weight: 700;
    margin: 3rem 0;
}
.flashcard > div.progress {
    height: 9px;
    padding: 0;
    border-radius: 0;
    background-color: #d9d9d9;
}
.flashcard > div.progress > div {
    background-color: #990d0e !important;
}
.flashcard .show_answer_btn {
    background-color: #c2c2c2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 14px 30px;
    border-radius: 30px;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    outline: none;
    transition: 0.2s ease;
    border: 1px solid #c2c2c2;
}

.flashcard .flashcard_btngroup {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #777;
}
.flashcard .flashcard_btngroup button {
    width: 25%;
    min-height: 100%;
    border-radius: 0px;
    background-color: #c2c2c2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 14px 30px;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    outline: none;
    transition: 0.2s ease;
    border: 1px solid transparent;
}
.flashcard .flashcard_btngroup button + button {
    border-left: 1px solid #777;
}
.flashcard .flashcard_btngroup button img {
    width: 35px;
}
.flashcard .show_answer_btn:hover,
.flashcard_btngroup button:hover {
    background-color: #bbb;
    border: 1px solid #bbb;
}
.flashcard .second_step p {
    font-weight: 700;
    text-align: center;
    margin: 3rem 0;
}
.flashcard button.btn_primary {
    width: 100%;
    border-radius: 8px;
    background-color: #990d0e;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 14px 30px;
    font-family: var(--secondary-font);
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    outline: none;
    transition: 0.2s ease;
    letter-spacing: 0.8px;
    border: 1px solid #990d0e;
    height: 51px;
}
.flashcard button.btn_primary svg {
    width: 28px;
    height: 28px;
}
.flashcard button.btn_primary {
    background-color: #880d0d;
    border: 1px solid #880d0d;
}

.text_editor_conent {
    user-select: none;
    margin: 2rem 0;
}
.text_editor_conent ul {
    padding-left: 24px;
}
.text_editor_conent ul > div,
.text_editor_conent ul li {
    white-space: break-spaces !important;
}
ul li{
    white-space: break-spaces !important;
}
p.text_editor_conent ul li span {
    font-size: 16px !important;
}
.text_editor_conent img {
    width: 100%;
    object-fit: contain;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    object-position: left;
}
.text_editor_conent td {
    border-width: 1px !important;
    padding: 8px;
}

.pdf_notes_list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pdf_notes_list li {
    display: flex;
    align-items: center;
    padding: 15px 25px 15px 15px;
    background: #f7f7f8;
    border: 1px solid #7e879f33;
    border-radius: 12px;
}

.pdf_notes_list li + li {
    margin-top: 15px;
}

.pdf_notes_list span {
    font-size: 19px;
    font-weight: 500;
    margin: 0 12px;
    color: #111112;
}

.pdf_notes_list .btn_light_solid {
    font-family: var(--secondary-font);
    color: #635d74;
    background: #fff;
    padding: 12px 25px;
    border-radius: 50px;
    border: none;
}

.pdf_notes_list .btn_light_solid:hover {
    color: #111112;
    background: #fcfcfc;
}

.flash_card_group .flash_card {
    margin-top: 30px;
}

.flash_card_group .flash_card .react-card-flip .CardFront {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.flash_card_group .flash_card:nth-child(3n + 1) .react-card-flip .CardFront {
    background-image: url(./assets/images/flash_card/flash_card_green.png);
}

.flash_card_group .flash_card:nth-child(3n + 2) .react-card-flip .CardFront {
    background-image: url(./assets/images/flash_card/flash_card_brown.png);
}

.flash_card_group .flash_card:nth-child(3n + 3) .react-card-flip .CardFront {
    background-image: url(./assets/images/flash_card/flash_card_blue.png);
}

.flash_card_group .flash_card .react-card-flip {
    min-height: 300px;
    display: flex;
}

.flash_card_group .flash_card .react-card-flipper {
    min-height: 300px;
    height: unset !important;
    display: flex;
}

.flash_card_group .flash_card .CardFront,
.flash_card_group .flash_card .CardBack {
    height: 100%;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flash_card_group .flash_card .CardFront h6 {
    font-family: var(--secondary-font);
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #111112;
    margin: 0 35px;
}

.flash_card_group .flash_card .CardBack {
    border: 2px solid #e4e3e7;
}

.flash_card_group .flash_card .CardBack p {
    margin: 0;
    font-family: var(--secondary-font);
    font-weight: 500;
    line-height: 24px;
}

.video_tab_card {
    margin-top: 30px;
    cursor: pointer;
    border: 1px solid #e4e3e7;
    border-radius: 12px;
    padding: 12px;
}

.video_tab_card video {
    margin: 0;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 8px;
}

.video_tab_card video source {
    margin: 0;
    width: 100%;
}

.video_tab_card h6 {
    font-family: var(--secondary-font);
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    margin: 12px 0 16px;
}

.iframe_for_vid_tab > iframe {
    aspect-ratio: 16/9;
    height: 100%;
    border-radius: 8px;
}

.error_banner {
    background-color: #990d0e;
    padding: 24px 0;
}

.error_banner .col-md-12 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error_banner h3 {
    font-family: var(--primary-font);
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 18px;
}

/* topic detail page end */
/* blur page start */
.blur_page {
    position: relative;
    min-height: 80vh;
}
.blur_page > img {
    width: 100%;
}
.blur_page > div {
    position: absolute;
    z-index: 1;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 20px #999;
}
/* blur page end */

/* badge / tag start */
.paid_badge {
    height: 20px;
    display: block;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    background-color: #555;
    width: 40px;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 500;
    margin: 2px;
}
.tag-primary {
    background-color: #990d0e;
    font-size: 12px;
    font-family: var(--tertiary-font);
    color: #fff;
    font-weight: 500;
    padding: 4px 8px;
    letter-spacing: 0.5px;
    display: inline-block;
}
.tag-dark {
    background-color: #3c3c3c;
    font-size: 12px;
    font-family: var(--tertiary-font);
    color: #fff;
    font-weight: 500;
    padding: 4px 8px;
    letter-spacing: 0.5px;
    display: inline-block;
}
/* badge / tag end */

/* universities start */
.universities_page {
    padding: 100px 0 200px;
}
.universities_heading {
    margin: 0 auto 60px;
    font-family: var(--primary-font);
    text-align: center;
    font-size: 70px;
    line-height: 84px;
}
.universities_card {
    background-color: #ffffff;
    box-shadow: 0px 7px 29px 0px #64646f33;
    border-radius: 20px;
    padding: 30px 20px;
    display: flex;
    gap: 20px;
}
.universities_card img {
    width: 35%;
    aspect-ratio: 1;
    object-fit: contain;
}
.universities_card > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}
.universities_card div h6 {
    font-size: 22px;
    line-height: 34px;
    font-weight: 400;
    font-family: var(--secondary-font);
}
.universities_card div p {
    margin-top: 10px;
    margin-bottom: 30px;
    color: #3c3c3c;
    font-size: 16px;
    line-height: 21px;
}
.universities_card > div > div {
    margin-top: auto;
    display: flex;
}
/* universities end */
/* universities detail start */
.university-detail-section {
    padding: 80px 0 100px;
}
.university-detail {
    margin-left: 2rem;
}
.university-detail h6 {
    font-family: var(--primary-font);
    text-transform: uppercase;
    font-size: 14px;
    color: #990d0e;
    margin-bottom: 10px;
}
.university-detail h2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    font-size: 28px;
    font-family: var(--fourth-font);
    font-weight: 400;
    line-height: 36px;
    color: #262626;
}
.university-detail h2 span {
    color: #990d0e;
}
.university-detail p {
    color: #262626;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: var(--secondary-font);
}
.university-detail-img {
    width: 100%;
    aspect-ratio: 4/3;
}
.university-course-card > div {
    display: flex;
}
.university-course-card > div > div {
    width: 50%;
}
.university-course-card > div > div > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.university-course-card > div h5 {
    font-size: 20px;
    margin-top: auto;
}
.university-course-card > div h6 {
    font-size: 14px;
}
.university-course-card > div > img {
    width: 50%;
    aspect-ratio: 3/4;
    object-fit: cover;
}
.university-course-card > h3 {
    font-size: 22px;
    color: #304351;
    font-family: var(--secondary-font);
    line-height: 26px;
    margin-top: 20px;
}
.university-course-card > p {
    font-size: 18px;
    color: #304351;
    line-height: 25px;
    font-family: var(--secondary-font);
    margin-top: 10px;
}
.buy_course_modal {
    padding: 0 !important;
}
.buy_course_modal .modal-content {
    border-radius: 20px;
    overflow: hidden;
}
.buy_course_modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: all;
    padding: 0;
}
.buy_course_box_detail {
    padding: 50px;
    background-color: #990d0e10;
}
.buy_course_box_detail .university-course-card > div:first-child {
    padding: 8px 8px 8px 0;
    background-color: #fff;
}
.buy_course_price {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    margin-top: 20px;
}
.buy_course_price > * {
    width: 50%;
}
.buy_course_price span {
    opacity: 50%;
    font-size: 18px;
    line-height: 21px;
    color: #111112;
}
.buy_course_price p {
    font-size: 18px;
    line-height: 21px;
    color: #111112;
}
.buy_course_detail {
    padding-left: 48px;
}
.buy_course_name {
    font-size: 18px;
    line-height: 24px;
    font-family: var(--primary-font);
}
.buy_course_desc {
    font-size: 22px;
    line-height: 26px;
    margin: 8px 0 0;
    font-family: var(--secondary-font);
}
.buy_course_include {
    font-size: 18px;
    line-height: 21px;
    margin: 30px 0 0;
    text-transform: uppercase;
    font-family: var(--secondary-font);
}
.buy_course_box_form {
    background-color: #e2e2e2;
    padding: 50px 0 100px;
}
.university-hero-section {
    aspect-ratio: 16 / 9;
    position: relative;
    width: 100%;
}
.university-hero-section img {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.university-hero-section::before {
    position: absolute;
    content: "";
    background-color: #e0e0e066;
    inset: 0;
}
.university-hero-section::after {
    position: absolute;
    content: "";
    background: url(./assets/images/red-shape.png) no-repeat;
    width: 100%;
    height: 100%;
    max-height: 495px;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.university-hero-section > div {
    position: absolute;
    left: 0;
    z-index: 2;
    bottom: 0;
    padding: 50px;
    max-width: 710px;
    overflow: hidden;
}
.university-hero-section h1 {
    font-family: var(--primary-font);
    font-size: 55px;
    color: #ffffff;
    text-align: center;
}
.university-hero-section p {
    font-family: var(--secondary-font);
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    margin: 20px 0 38px;
}
.university-hero-section > div > div {
    display: flex;
    justify-content: center;
}
.university-subject-heading {
    font-family: var(--primary-font);
    font-size: 60px;
    font-weight: 700;
    line-height: 80px;
    color: #111112;
    text-align: center;
}
.university-year-heading {
    font-family: var(--primary-font);
    font-size: 40px;
    line-height: 40px;
    margin: 40px 0;
    color: #111112;
}
/* universities detail end */

.contactus_page {
    padding: 100px 0 200px;
}

.faq_accordian h2 {
    font-family: var(--primary-font);
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    padding: 16px;
}
.free_trial_modal {
    /* padding: 0 !important; */
}
.free_trial_modal .modal-content {
    border-radius: 20px;
}
.free_trial_modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: all;
    padding: 4rem 3rem;
}
.free_trial_modal .modal-body > h3 {
    text-align: center;
    font-family: var(--primary-font);
    font-weight: 700;
    margin: 60px 0 0;
}
.free_trial_modal .modal-body > p {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-family: var(--secondary-font);
    margin: 24px 0 0;
}
.free_trial_modal .modal-body > div {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 48px 0 0;
    button {
        font-family: var(--tertiary-font);
    }
}

/* study planner start */
.study-planner {
    padding: 70px 0 100px;
}
.study-planner-heading {
    font-size: 30px;
    line-height: 40px;
    font-family: var(--primary-font);
    margin-bottom: 40px;
}
.planner-board {
    display: flex;
    gap: 20px;
    padding: 30px;
    box-shadow: 0px 7px 29px 0px #64646f33;
}
.board-topic-list {
    box-shadow: 0px 7px 29px 0px #64646f33;
    padding: 30px 16px;
    width: 24%;
}
.board-topic-list h4 {
    font-size: 21px;
    font-family: var(--secondary-font);
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 40px;
}
.board-topic-list select {
    margin-bottom: 20px;
    border: 1px solid #777777cc;
    font-family: var(--secondary-font);
    height: 40px;
}
.board-topic-list select.form-select:focus {
    border-color: #990d0e;
    outline: 0;
    box-shadow: none;
}
.board-topic-list select * {
    font-family: var(--secondary-font);
}
.board-topic-list p {
    font-size: 20px;
    font-family: var(--secondary-font);
    line-height: 24px;
    font-weight: 500;
}
.board-topic-list ul {
    list-style: none;
    padding: 0;
}
.board-topic-list ul li {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: var(--secondary-font);
    padding: 4px 0;
}
.board-calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.calendar-actions {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.calendar-actions > div {
    display: flex;
    align-items: center;
    gap: 16px;
}
.calendar-actions h5 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    font-family: var(--tertiary-font);
}
.calendar > div {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border-top: 1px solid #6e6e6e;
    border-left: 1px solid #6e6e6e;
}
.calendar .calendar-day {
    height: 30px;
    padding: 4px 8px;
    font-size: 18px;
    font-family: var(--secondary-font);
    text-transform: uppercase;
    color: #990d0e;
    border-bottom: 1px solid #6e6e6e;
    border-right: 1px solid #6e6e6e;
}
.calendar .calendar-date {
    min-height: 100px;
    padding: 4px 8px;
    border-bottom: 1px solid #6e6e6e;
    border-right: 1px solid #6e6e6e;
    position: relative;
}
.calendar .calendar-date > p {
    font-size: 18px;
    font-family: var(--tertiary-font);
    color: #990d0e;
    font-weight: 600;
}
.calendar .calendar-date > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.calendar .calendar-date li {
    color: #fff;
    background-color: #8b36b1;
    border-left: 3px solid #29003c;
    padding: 6px 4px;
}
/* study planner end */

.overview-images-container.embedded-widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
}

.free_trial_modal {
    /* padding: 0 !important; */
}
.free_trial_modal .modal-content {
    border-radius: 20px;
}
.free_trial_modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: all;
    padding: 4rem 3rem;
}
.free_trial_modal .modal-body > h3 {
    text-align: center;
    font-family: var(--primary-font);
    font-weight: 700;
    margin: 60px 0 0;
}
.free_trial_modal .modal-body > p {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-family: var(--secondary-font);
    margin: 24px 0 0;
}
.free_trial_modal .modal-body > div {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 48px 0 0;
    button {
        font-family: var(--tertiary-font);
    }
}

div.dangerously-set-html > p {
    display: flex !important;
}
div.dangerously-set-html > p > img {
    width: 100% !important;
}
div.dangerously-set-html li {
    white-space: normal !important;
}
div.dangerously-set-html span {
    white-space: normal !important;
    height: unset !important;
    width: unset !important;
}
div.dangerously-set-html {
    width: 100% !important;
    height: 100% !important;
}

/* media query start */

@media (max-width: 1399px) {
    .board-topic-list {
        width: 28%;
    }
}
@media (min-width: 992px) and (max-width: 1399px) {
    .navbar_logo.pe-5 {
        padding-right: 1.5rem !important;
    }
    .isnotloggedin.navbar_auth_profile_btn {
        .btn_text {
            font-size: 14px;
        }
        .btn_primary {
            width: 120px;
            font-size: 14px;
            text-wrap-mode: nowrap;
        }
        .btn_primary_outline {
            width: 120px;
            font-size: 14px;
            text-wrap-mode: nowrap;
        }
    }
    .nav_links_lg li {
        font-size: 14px;
    }
}
@media (max-width: 1199px) {
    .header_auth_btn {
        width: 120px;
    }
    /* home page start */
    /* hero section start */
    .hero_section > div {
        top: 25%;
    }

    .hero_section .hero_heading {
        font-size: 50px;
        line-height: 65px;
    }

    /* hero section end */
    .home_havequestion_section img {
        height: 740px;
    }
    /* subject section start */
    .subject_info h2 {
        font-size: 46px;
        padding-right: 5rem;
    }

    /* subject section end */
    /* tution hub section start */
    .tution_hub_content h2 {
        font-size: 46px;
        padding-right: 5rem;
    }

    /* tution hub section end */
    /* blog section start */
    .blog_section_content h2 {
        font-size: 46px;
    }

    /* blog section end */
    /* faq section start */
    .faq_section_content h2 {
        font-size: 46px;
    }

    .faq_section_content p {
        padding-right: 0;
    }

    /* faq section end */
    /* home page end */
    .tutionhub_howitworks p.description {
        padding-right: 5rem;
    }
    .universities_card img {
        width: 30%;
    }
    .university-hero-section h1 {
        font-size: 55px;
        line-height: 55px;
    }
    .planner-board {
        flex-direction: column;
    }
    .board-topic-list {
        display: flex;
        width: 100%;
        gap: 48px;
    }
}

@media (max-width: 991px) {
    /* header/navbar start */
    .header_auth_btn {
        width: 180px;
    }
    .navbar_top,
    .navbar_location_search,
    .navbar_links,
    .nav_links_lg {
        display: none;
    }

    nav.navbar {
        padding: unset;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    nav.navbar .navbar-brand svg {
        width: 100px;
    }

    .humburger_menu {
        display: unset;
        background: none;
        border: none;
        transition: all 0.3s ease;
    }

    .humburger_menu span {
        display: block;
        height: 3px;
        width: 25px;
        background-color: #990d0e;
        margin: 5px 0 5px auto;
        border-radius: 4px;
        transition: all 0.3s ease;
    }

    .humburger_menu .line_1 {
        width: 20px;
    }

    .humburger_menu .line_3 {
        width: 15px;
    }

    .humburger_menu.menu_nav_active .line_1 {
        width: 25px;
        transform: scale(-1) rotate(135deg) translateY(5px);
    }

    .humburger_menu.menu_nav_active .line_2 {
        display: none;
    }

    .humburger_menu.menu_nav_active .line_3 {
        width: 25px;
        transform: rotate(45deg) translateY(-5px);
    }

    .offcanvas-backdrop,
    .offcanvas.offcanvas-end {
        top: 89px !important;
    }

    .offcanvas-backdrop.show {
        opacity: 0.4 !important;
    }

    .navbar_auth_profile_btn .navbar_auth_profile_btn_dropdown {
        left: 0 !important;
        right: 0 !important;
        bottom: 55px !important;
        width: 100%;
        transform: none !important;
    }

    /* header/navbar end */

    /* home page start */
    /* hero section start */
    .hero_section > div {
        top: 30%;
    }

    .hero_section .hero_heading {
        font-family: Playfair Display;
        font-size: 48px;
        font-weight: 700;
        line-height: 55px;
        text-align: left;
    }
    .primary_pixel_btn_bg {
        max-width: 120px;
    }
    .hero_img {
        margin-top: 48px;
    }
    .helper-banner {
        padding: 32px 24px;
        .count-box {
            padding: 0 16px;
            span {
                font-size: 54px;
            }
            h6 {
                font-size: 22px;
            }
        }
    }

    /* hero section end */
    /* tution hub section start */
    .home_tution_section {
        padding: 64px 0 116px;
    }

    /* tution hub section end */
    .get_started_info h2 {
        line-height: 64px;
        padding-right: 64px;
    }
    /* faq section start */

    .faq_accordian .accordion {
        margin-top: 2rem;
    }

    .faq_accordian .accordion-button {
        padding: 20px 3rem 20px 0;
        font-size: 24px;
    }

    /* faq section end */
    .home_havequestion_section img {
        height: 640px;
    }
    /* home page end */
    /* login/signup start */
    .login_signup_page {
        height: auto;
    }

    .login_signup_page::before {
        width: 100%;
        height: 40%;
        background-position-y: center;
    }

    .login_signup_page .login_signup_content_left {
        margin: 7rem;
    }

    /* login/signup end */
    /* blog card component start */
    .blog_card_component_img img {
        aspect-ratio: 16/9;
    }

    /* blog card component end */
    /* aboutus page start */
    .aboutus_page_content h4 {
        font-size: 34px;
    }

    /* aboutus page end */
    /* tution hub page start */
    .tutionhub_hero_section h2 {
        font-size: 46px;
    }

    .tutionhub_hero_section p {
        font-size: 16px;
    }

    .tutionhub_whychoose .section_heading {
        font-size: 48px;
    }

    .tutionhub_howitworks p.description {
        padding-right: 12px;
    }

    /* tution hub page end */
    .universities_card {
        flex-direction: column;
        align-items: center;
    }

    .universities_card img {
        width: 35%;
    }
    .aboutus_page_content > div:last-child {
        align-items: center;
    }
    .aboutus_page_content button {
        width: 100%;
    }
    .university-detail {
        margin-left: 0;
    }
    .university-detail-img {
        width: 100%;
        aspect-ratio: 6/4;
        object-fit: cover;
        object-position: center;
        margin-bottom: 2rem;
    }
    .university-hero-section {
        aspect-ratio: unset;
        height: 650px;
    }
    .university-hero-section img {
        aspect-ratio: unset;
        height: 650px;
    }
    .university-hero-section > div {
        padding: 32px;
        max-width: 652px;
    }
    .buy_course_box_detail {
        padding: 50px 24px;
    }
    .buy_course_detail {
        padding: 24px 0 0;
    }
    .buy_course_box_form {
        padding: 32px 24px 60px;
    }
}

@media (max-width: 767px) {
    /* home page start */
    /* hero section start */
    .hero_section {
        /* height: 600px; */
    }

    .hero_section > img {
        height: 100%;
    }

    .hero_section .hero_heading {
        font-size: 40px;
    }
    .helper-banner {
        flex-direction: column;
        gap: 48px;
        .count-box {
            display: flex;
            gap: 32px;
            align-items: flex-start;
            span {
                min-width: 30%;
                font-size: 48px;
            }
            h6 {
                margin: 0 0 16px;
            }
        }
    }

    /* hero section end */
    .home_university_logos {
        overflow-x: scroll;
        padding-bottom: 24px;
    }
    /* subject section start */
    .subject_info h2 {
        font-size: 38px;
        padding-right: 0;
    }

    /* subject section end */
    /* tution hub section start */
    .tution_hub_content {
        padding-left: 12px !important;
    }
    .tution_hub_content h2 {
        font-size: 38px;
        padding-right: 5rem;
    }
    /* tution hub section end */
    .get_started_features div img {
        width: 55px;
        height: 55px;
    }
    .get_started_features div span {
        font-size: 18px;
    }
    /* blog section start */
    .blog_section_content h2 {
        font-family: var(--primary-font);
        font-size: 38px;
        font-weight: 700;
        text-align: center;
    }

    /* blog section end */
    /* faq section start */
    .faq_section_content h2 {
        font-family: var(--primary-font);
        font-size: 38px;
        font-weight: 700;
    }

    .faq_accordian .accordion-body {
        padding: 0 2rem 2rem 1rem;
    }

    /* faq section end */
    .havequestion_content h2 {
        font-size: 48px;
        line-height: 54px;
    }
    /* home page end */
    /* aboutus page start */
    .aboutus_page_content > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .aboutus_page_content img {
        max-width: 300px;
        margin: auto;
    }

    /* aboutus page end */
    .account_page .tab_content_box .tab_content_heading {
        padding: 32px 0 18px;
    }

    .account_page .tab_content_box .tab_content {
        padding: 8px 0 25px;
    }

    /* tution hub page start */
    .tutionhub_hero_section > .container > .row {
        flex-direction: column-reverse;
    }

    .tutionhub_hero_section img {
        max-width: 250px;
        margin: auto;
    }

    .tutionhub_hero_section h2 {
        font-size: 40px;
    }

    .tutionhub_whychoose .section_heading {
        font-size: 42px;
    }

    /* tution hub page end */
    .topic_detail .accordion {
        margin-top: 0;
    }
    .university-hero-section > div {
        padding: 48px;
        max-width: 100%;
    }
    .university-hero-section h1 {
        font-size: 50px;
        line-height: 60px;
    }
    .board-topic-list {
        flex-direction: column;
        gap: 32px;
    }
    .calendar > div {
        overflow-x: scroll;
    }
    .calendar .calendar-day {
        min-width: 150px;
    }
}

@media (max-width: 575px) {
    .hero_section .hero_heading {
        font-size: 32px;
        line-height: 48px;
    }

    .get_started_features div {
        width: 50% !important;
    }
    .get_started_features div:nth-child(4) {
        border-bottom: 1px solid #b2b2b2;
    }
    .get_started_features div:nth-child(2) {
        border-right: 0;
    }
    .get_started_features div:nth-child(5) {
        border: none;
    }
    .get_started_features div:nth-child(4),
    .get_started_features div:nth-child(6) {
        border-left: 1px solid #b2b2b2;
    }
    .userreview_heading {
        font-size: 40px;
        line-height: 54px;
    }
    .topic_review_select li > div {
        height: 100%;
    }

    .topic_review_select li > div > div {
        padding: 16px 4px;
        height: 100%;
        justify-content: space-around;
    }

    .topic_answer_tabs li button {
        padding: 20px 4px;
        font-size: 15px;
        word-break: break-word;
    }

    .topic_review_select li p {
        text-align: center;
        font-size: 15px;
        word-break: break-word;
    }
    .flashcard .flashcard_btngroup {
        display: flex;
        flex-wrap: wrap;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #777;
    }
    .flashcard .flashcard_btngroup button {
        width: 50%;
        font-size: 14px;
    }
    .flashcard .flashcard_btngroup button:nth-child(3) {
        border-left: none;
        border-top: 1px solid #777;
    }
    .flashcard .flashcard_btngroup button:nth-child(4) {
        border-top: 1px solid #777;
    }
    .flashcard .flashcard_btngroup button img {
        width: 25px;
    }
    .university-hero-section > div {
        padding: 16px;
        max-width: 100%;
    }
    .free_trial_modal .modal-body > div {
        flex-direction: column;
    }
    .calendar-actions > div {
        flex-wrap: wrap;
    }
    .calendar-actions > div h5 {
        width: 100%;
    }
    .calendar-actions > div button {
        width: 35%;
    }
    .calendar-actions > div button:last-child {
        margin-left: auto;
    }
    .free_trial_modal .modal-body > div {
        flex-direction: column;
    }
}
@media (max-width: 531px) {
    .login_signup_page::before {
        height: 47%;
    }
}

.modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.modal-body {
    flex: 1;
}

.modal-footer {
    margin-top: auto;
}

.pass_error_msg {
    color: crimson;
    font-size: 13px;
}

/* media query end */
